<template>
  <transition name="fade" v-on:enter="enter">
    <div class="message" v-if="isMessage">
          <img
            src="../../assets/Icons/right.svg"
            alt
          />
        <span class="text">
          {{message}}
        </span>
    </div>
  </transition>
</template>

<script>
export default {
    name: 'message',
    props: {
      message: {
        type: String,
        required: ''
      }
    },
    data() {
      return {
        isMessage: false,
      }
    },
    methods: {
      fadeMe: function() {
        this.isMessage = !this.isMessage
      },
      enter() {
        var that = this;
        setTimeout(function() {
          that.isMessage = '';
        }, 3000);
      }
    }
}
</script>

<style scoped>
.message {
  width: 455px;
  height: 70px;
  padding: 12px 16px 16px 16px;
  object-fit: contain;
  border-radius: 3px;
  background-color: #55ea76;
  margin: auto;
  position: fixed;
  z-index: 9;
  margin-top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.message .text {
  margin: 14px 0 14px 15px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s
}

.fade-enter,
.fade-leave-to
{
  opacity: 0
}
</style>
